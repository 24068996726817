import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { HelmetDatoCms } from 'gatsby-source-datocms'

const AboutGrid = styled.section`
    display:grid;
    grid-template-columns: 1fr 1.5fr;
    width:1280px;
    margin:150px auto 0 auto;
    grid-column-gap: 30px;
    @media(max-width:1350px){
      width:960px;
    }
    @media(max-width:1000px){
      width:90%;
    }
    @media(max-width:750px){
      display:block;
      .content{
        margin: 50px auto;
        width:90%;
      }
    }
    .content{
        width:75%;
    }
`

const BlockQuote = styled.blockquote`
    quotes: "“" "”"; /* one pair */
    text-align:center;
    font-size:40px;
    width: 50%;
    margin: 0 auto;
    padding: 100px 0;
    @media(max-width:1000px){
      font-size:26px;
      line-height:32px;
    }
    @media(max-width:700px){
      width:75%;
    }
    &:before{
      content: open-quote;
      font-size:60px;
      @media(max-width:1000px){
        font-size:40px;
      }
    }
    &:after{
      content: close-quote;
      font-size:60px;
      @media(max-width:1000px){
        font-size:40px;
      }
  `

  const DesignDiagram = styled.section`
      width:1000px;
      margin: 0 auto;
      @media(max-width:1050px){
        width:90%;
      }
  `

const About = ({data}) => {
    return(
        <Layout>
          <HelmetDatoCms seo={data.datoCmsAboutPage.seoMetaTags}/>
            <AboutGrid>
                <div>
                  <Img fluid={data.datoCmsAboutPage.profileImage.fluid} />  
                </div>
                <div className="content">
                    <h1>{data.datoCmsAboutPage.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html:data.datoCmsAboutPage.aboutMe}}/>
                </div>
                  
            </AboutGrid>

            <BlockQuote>
            {data.datoCmsAboutPage.calloutQuote}  
            </BlockQuote>

            <DesignDiagram>
              <Img fluid={data.datoCmsAboutPage.designDiagram.fluid} />  
            </DesignDiagram>
            
        </Layout>
    )
}

export default About;

export const query = graphql`
  query AboutQuery {
    datoCmsAboutPage{
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        aboutMe
        calloutQuote
        profileImage{
            fluid(maxWidth:500) {
                ...GatsbyDatoCmsFluid
            }
        }
        designDiagram{
          fluid(maxWidth:1200) {
              ...GatsbyDatoCmsFluid
          }
      }
    }
  }
`